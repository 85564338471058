'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useLocale, useTranslations } from 'next-intl';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { pushFBPixelEvent } from '@components/Analytics/Facebook/FBPixelEvent';
import { pushGAEvent } from '@components/Analytics/Google/GAScript';
import { Checkbox, Input, Select, Button } from '@components/Form'
import { ExpandableText } from '@components/Form/Checkbox';
import { toastError, toastSuccess } from '@components/root/NotificationProxy';
import { submitIpressoAgreements } from '@lib/ipresso';
import { IPRESSO_NEWSLETTER_USER_TAG } from '@utils/api/client/ipresso';
import { showNewsletter, newslettersByCountries } from '@utils/newsletter';
import { useUserStore } from '@utils/user';
import { Language } from 'types/lang';

type Props = {
  signOnMainPage?: boolean;
};

const Newsletter = ({ signOnMainPage }: Props) => {
  const lang = useLocale();

  // if (!showNewsletter(lang as langTypes)) return null;
  const langType: Language | any = lang;
  if (!showNewsletter(langType)) return null;

  if (!signOnMainPage) {
    return (
      <NewsletterWithoutSignOn />
    );
  } else {
    return (
      <NewsletterWithSignOn />
    );
  }
};

const NewsletterWithoutSignOn = () => {
  const lang = useLocale();
  const userStore = useUserStore();
  const t = useTranslations();
  const labels = {
    title: t('newsletter.component.title'),
    subTitle: t('newsletter.component.subTitle'),
    descriptionStart: t('newsletter.component.descriptionStart'),
    descriptionEnd: t('newsletter.component.descriptionEnd'),
    submit: t('newsletter.component.submit'),
  };

  if (userStore.isLoggedIn) {
    return (<></>);
  }

  return (
    <div className="newsletter-no-signon-component">
      <div className="newsletter-no-signon-component_title">{labels.title}</div>
      <div className="newsletter-no-signon-component_title">{labels.subTitle}</div>
      <div className="newsletter-no-signon-component_description">{labels.descriptionStart} {labels.descriptionEnd}</div>
      <div>
        <a href={`/${lang}/newsletter`} className="newsletter-no-signon-component_button">{labels.submit}</a>
      </div>
    </div>
  );
}

const schema = z.object({
  email: z.string(),
  occupation: z.string().optional(),
  marketingAgreement: z.boolean().refine((value) => value === true),
  commercialOutside: z.boolean(),
  commercialInside: z.boolean(),
});

type SchemaType = z.infer<typeof schema>;

const NewsletterWithSignOn = () => {
  const lang = useLocale();
  const userStore = useUserStore();
  const t = useTranslations();
  const labels = {
    title: t('newsletter.component.title'),
    subTitle: t('newsletter.component.subTitle'),
    descriptionStart: t('newsletter.component.descriptionStart'),
    descriptionEnd: t('newsletter.component.descriptionEnd'),
    emailTooltip: t('newsletter.component.emailTooltip'),
    occupationTooltip: t('newsletter.component.occupationTooltip'),
    checkAll: t('main.agreement.all'),
    marketing: {
      label: t('main.agreement.marketing.label'),
      contents: t('main.agreement.marketing.contents'),
    },
    commercialInside: {
      label: t('main.agreement.commercialInside.label'),
      contents: t('main.agreement.commercialInside.contents'),
    },
    commercialOutside: {
      label: t('main.agreement.commercialOutside.label'),
      contents: t('main.agreement.commercialOutside.contents'),
    },
    rodo: {
      label: t('main.agreement.rodo.label'),
      contents: t('main.agreement.rodo.contents'),
    },
    submit: t('newsletter.component.submit'),
    error: t('newsletter.component.notify.error.message'),
    success: t('newsletter.component.notify.success.message'),
  };

  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const allCheckRef = useRef<HTMLInputElement>(null);
  const defaultValues = {
    email: '',
    occupation: undefined,
    marketing: false,
    commercialInside: false,
    commercialOutside: false,
  }
  const occupationOptions = newslettersByCountries[lang]
    .map((occupation) => {
      return {
        label: `${t(
          `newsletter.workingOccupation.${occupation}`,
        )}`,
        value: occupation,
      };
    });

  const {
    watch,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors }
  } = useForm<SchemaType>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const onAcceptAllChange = (e) => {
    setValue('marketingAgreement', !!e.target.checked);
    setValue('commercialInside', !!e.target.checked);
    setValue('commercialOutside', !!e.target.checked);
  }

  const checkboxesWatched = (['marketingAgreement', 'commercialInside'] as const).map((name) => watch(name));
  const emailWatched = watch('email');
  const occupationWatched = watch('occupation');

  useEffect(() => {
    setIsAccepted(!!emailWatched && !!occupationWatched && checkboxesWatched.reduce((acc, curr) => acc && curr, true) && !isLoading);
  }, [emailWatched, occupationWatched, ...checkboxesWatched, isLoading]);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(emailRegex.test(e.target.value) && !!occupationWatched && checkboxesWatched.reduce((acc, curr) => acc && curr, true) && !isLoading);
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const tags = [IPRESSO_NEWSLETTER_USER_TAG];
    const dataForIpresso = {
      "marketingAgreement": data.marketingAgreement,
      "commercialInside": data.commercialInside,
      "commercialOutside": data.commercialOutside || false,
      "locale": lang,
      "email": data.email,
      "categoryData": {
        [data.occupation!]: true
      },
      tags,
    }

    const response = await submitIpressoAgreements(dataForIpresso);
    if (response?.status === 201) {
      setIsLoading(false);
      reset();
      toastSuccess(labels.success);
      const userInfo = userStore.getUserInfoForAnalytics();
      pushFBPixelEvent('Lead',
        {
          ...userInfo,
          content_name: 'newsletter-subscription',
          transinfo_newsletter_email_subscribed: data.email,
          transinfo_newsletter_lang: lang,
          transinfo_newsletter_categories: data.occupation!
        }
      );
      pushGAEvent(userStore, 'transinfo_newsletter_subscription', lang, {
        transinfo_view_category: data.occupation!,
        transinfo_newsletter_email: data.email
      });
    } else {
      setIsLoading(false);
      toastError(labels.error);
    }
  });

  if (userStore.isLoggedIn) {
    return (<></>);
  }

  return (
    <div className="newsletter-signon-component">
      <div className="newsletter-signon-component_left">
        <div className="newsletter-signon-component_title">{labels.title}</div>
        <div className="newsletter-signon-component_title">{labels.subTitle}</div>
      </div>
      <div className="newsletter-signon-component_right">
        <form noValidate onSubmit={onSubmit} className="newsletter-signon-component_form" >
          <div className="newsletter-signon-component_description">
            <b>{labels.descriptionStart}</b> {labels.descriptionEnd}
          </div>
          <div className="newsletter-signon-component_email">
            <Input
              required
              {...register('email')}
              placeholder={labels.emailTooltip}
              onChange={onEmailChange}
            />
          </div>
          <div className="newsletter-signon-component_occupation">
            <Select
              required
              {...register('occupation')}
              placeholder={labels.occupationTooltip}
              options={occupationOptions}
              defaultValue={labels.occupationTooltip}
            />
          </div>
          <div className="newsletter-signon-component_agreements">
            <Checkbox
              onChange={onAcceptAllChange}
              ref={allCheckRef}
              wrapperClassName='font-medium mb-2 lg:mb-3'
              className="mr-2"
              name="all"
              label={labels.checkAll}
            />
            <Checkbox
              required
              {...register('marketingAgreement')}
              error={!!errors?.marketingAgreement}
              wrapperClassName='mb-2 lg:mb-3'
              name="marketingAgreement"
              label={labels.marketing.label}
              tooltip={labels.marketing.contents}
            />
            <Checkbox
              required
              {...register('commercialInside')}
              error={!!errors?.commercialInside}
              wrapperClassName='mb-2 lg:mb-3'
              name="commercialInside"
              label={labels.commercialInside.label}
              tooltip={labels.commercialInside.contents}
            />
            <Checkbox
              {...register('commercialOutside')}
              error={!!errors?.commercialOutside}
              wrapperClassName='mb-2 lg:mb-3'
              name="commercialOutside"
              label={labels.commercialOutside.label}
              tooltip={labels.commercialOutside.contents}
            />
            <div className="newsletter-signon-component_expandable">
              <ExpandableText
                error={false}
                text={labels.rodo.label}
                expandedText={labels.rodo.contents}
                required={false}
              />
            </div>
          </div>
          <div>
            <Button disabled={!isAccepted} className="newsletter-signon-component_button">{labels.submit}</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
