'use client';

import _get from 'lodash/get';
import { useTranslations } from 'next-intl';

import { Wall } from '@lib/main/transform';
import { Link } from '@lib/navigation';
import { useUserStore } from '@utils/user';


type ExplicitPromotedPublicationsProps = {
  itemType: 'explicit';
  title?: string;
  description?: string;
  translate?: boolean;
  imageSrc?: string;
  imageAlt?: string;
  downloadUrl?: string;
  downloadUrlPremium?: string;
  backgroundColor?: string;
} & CommonProps;

type CommonProps = {
  className?: string;
  wrapperClassName?: string;
  imageContainerClassName?: string;
  imageClassName?: string;
};

const variableNames = [
  'title',
  'description',
  'imageSrc',
  'imageAlt',
  'downloadUrl',
  'downloadUrlPremium',
  'backgroundColor',
] as const;

type Variable = (typeof variableNames)[number];

type DerivedPromotedPublicationProps = {
  itemType: 'derived';
  dataSourceObjectName: string;
  alternativeVariables: {
    [key in Variable]?: string;
  };
} & (
  | {
      dataType: 'single';
    }
  | {
      index: number;
      dataType: 'list';
    }
) &
  CommonProps;

export type PromotedPublicationsProps = ExplicitPromotedPublicationsProps | DerivedPromotedPublicationProps;
type PromotedPublicationsPropsWithWall = PromotedPublicationsProps & { wall: Wall };

const useExplicitProps = (props: PromotedPublicationsPropsWithWall): ExplicitPromotedPublicationsProps => {
  const userStore = useUserStore();
  const explicitProps = {
    className: props.className,
    wrapperClassName: props.wrapperClassName,
    imageContainerClassName: props.imageContainerClassName,
    imageClassName: props.imageClassName,
  } as ExplicitPromotedPublicationsProps;

  if (props.itemType === 'explicit') {
    explicitProps.title = props.title;
    explicitProps.description = props.description;
    explicitProps.backgroundColor = props.backgroundColor;
    explicitProps.imageSrc = props.imageSrc;
    explicitProps.imageAlt = props.imageAlt;
    explicitProps.downloadUrl = props.downloadUrl;
    explicitProps.downloadUrlPremium = props.downloadUrlPremium;
  } else {
    let dataSourceObject = props.wall[props.dataSourceObjectName];
    if (props.dataType != 'single') {
      dataSourceObject = props.wall[props.dataSourceObjectName][props.index];
      // so
    }
    if (typeof dataSourceObject === 'undefined') {
      return explicitProps;
    }
    variableNames.forEach((variableName) => {
      explicitProps[variableName] =
        props.alternativeVariables && props.alternativeVariables[variableName]
          ? _get(dataSourceObject, props.alternativeVariables[variableName])
          : _get(dataSourceObject, variableName);
    });
  }

  explicitProps.downloadUrl = userStore.isPremium ? explicitProps.downloadUrlPremium : explicitProps.downloadUrl;

  return explicitProps;
};

const PromotedPublication = (props: PromotedPublicationsPropsWithWall) => {
  const t = useTranslations('front');
  const {
    className,
    wrapperClassName,
    imageClassName,
    imageContainerClassName,
    title,
    description,
    backgroundColor,
    imageSrc,
    imageAlt,
    downloadUrl,
  } = useExplicitProps(props);

  const browseUrl = `/page/e-magazyn`;

  return (
    <div
      className={
        wrapperClassName ? wrapperClassName : `w-full ${!backgroundColor ? 'bg-orange-dim' : `bg-[${backgroundColor}]`}`
      }
    >
      <div
        className={
          className
            ? className
            : 'mx-auto my-12 mt-20 flex max-w-[1200px] flex-col items-center items-center justify-center text-premium-dark lg:flex-row'
        }
      >
        <div
          className={
            imageContainerClassName
              ? imageContainerClassName
              : 'relative flex h-[320px] min-w-[200px] flex-shrink-0 flex-col items-end'
          }
        >
          <img
            src={imageSrc}
            alt={imageAlt}
            className={imageClassName ? imageClassName : 'relative top-[-40px] h-[340px] lg:min-h-[320px]'}
          />
        </div>
        <div className="ml-4 p-8  lg:w-1/2">
          <h2 className="text-2xl font-bold">{title}</h2>
          <p className="mt-2 text-lg font-medium">{description}</p>
          <div className="mt-4 flex space-x-4">
            <Link
              href={downloadUrl ? downloadUrl : '#'}
              className="rounded-full bg-premium-dark px-4 py-2 text-center text-white shadow-md hover:bg-gray-300 hover:text-premium-dark"
            >
              {t('ebook.downloadCTA')}
            </Link>
            <Link
              href={browseUrl}
              className="rounded-full bg-white px-4 py-2 text-center text-premium-dark shadow-md hover:bg-gray-300"
            >
              {t('ebook.browseCTA')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromotedPublication;
