'use client';

import { useTranslations } from 'next-intl';

import { Link } from '@lib/navigation';

export type ButtonLinkProps = {
  href: string;
  text: string;
  className?: string;
  translate?: boolean;
  target?: string;
};

const ButtonLink = ({ href, text, target, className, translate }: ButtonLinkProps) => {
  const t = useTranslations();
  if (translate) {
    text = t(text);
  }
  return (
    <Link
      href={href}
      target={target}
      className={
        className
          ? className
          : 'inline-block rounded-full bg-white px-4 py-2 text-premium-900 shadow-xl hover:bg-gray-300'
      }
    >
      {text}
    </Link>
  );
};
export default ButtonLink;
