'use client';

import { useLocale, useTranslations } from 'next-intl';
import { MouseEventHandler, useEffect } from 'react';

import GAScript, { pushGAEvent } from '@components/Analytics/Google/GAScript';
import { Banner } from '@utils/api/server/wr';
import { useUserStore } from '@utils/user';

export type BannersMainTopProps = {
  target: string;
  banners: Banner[];
};

const BannersMainTop = ({ target, banners }: BannersMainTopProps) => {

  const lang = useLocale();
  const t = useTranslations('main.label');
  const userStore = useUserStore();
  const numberOfBanners = banners.length;

  if (numberOfBanners === 0) return null;

  const advertisementLabel = t('advertisement').toUpperCase();

  const getBanner = () => {
    const index = new Date().getMinutes() % numberOfBanners;
    const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const analyticsCustomParams = {
      locale: lang,
      transinfo_view_category: windowWidth > 768 ? 'external-banner-desktop' : 'external-banner-mobile',
      transinfo_url: banners[index].url,
      transinfo_title: banners[index].description,
      transinfo_extension_1: banners[index].imgSrcDesktop,
      transinfo_extension_2: banners[index].imgSrcMobile,
      transinfo_extension_3: windowWidth
    };
    return { banner: banners[index], analyticsCustomParams };
  }

  let { banner, analyticsCustomParams } = getBanner();

  useEffect(() => {
    ({ banner, analyticsCustomParams } = getBanner());
  }, [])

  const onClickHandler: MouseEventHandler<HTMLAnchorElement> = () => {
    pushGAEvent(userStore, 'transinfo_banner_click', lang, analyticsCustomParams);
  };

  return (
    <div className="w-full h-full my-8">
      <div className="w-full text-left font-normal text-xs leading-xs mb-2">{advertisementLabel}</div>
      <div className="hidden md:block px-2 lg:px-0">
        <a href={banner.url} target={target} onClick={onClickHandler}>
          <img src={banner.imgSrcDesktop} alt={banner.description} className="w-full rounded-2xl" />
        </a>
      </div>
      <div className="block md:hidden px-2">
        <a href={banner.url} target={target} onClick={onClickHandler}>
          <img src={banner.imgSrcMobile} alt={banner.description} className="w-full rounded-2xl" />
        </a>
      </div>
      <GAScript event='transinfo_banner_view' id={banner.id} values={analyticsCustomParams} />
    </div>
  );
};
export default BannersMainTop;
