import jwtDecode from 'jwt-decode';
import qs from 'qs';

import { type WROAuthUser, type CustomJwtPayload } from '../server';

import { ApiV2Error, isApiV2Error } from './error';

import { logger } from '@logger';
import { delay } from '@utils/common';
export { isApiV2Error } from './error';
export { redirectToLinkedInLogin, redirectToTransIdLogin } from './auth/social';

export type ApiV2Response = {
  [key: string]: any;
} | null;

export interface FetchApiV2Result<T extends ApiV2Response> {
  data: T;
  status: number;
  response: Response;
}

export interface ApiV2User extends WROAuthUser {
  token: string;
}

const API_PATH = 'apis';

export const JWT_TOKEN_KEY = 'token-v2';
const USER_KEY = 'user-v2';
export interface ApiV2RequestInit extends RequestInit {
  noAuth?: boolean;
  params?: Record<string, any>;
  onFailure?: 'continue' | 'abort';
  retries?: number;
  retryCounter?: number;
}

type ResolvedApiV2RequestInit = Omit<ApiV2RequestInit, 'retries' | 'retryCounter' | 'headers'> &
  Required<Pick<ApiV2RequestInit, 'retries' | 'retryCounter' | 'headers'>>;

const resolveRequestInit = (init: ApiV2RequestInit): ResolvedApiV2RequestInit => {
  return {
    ...init,
    headers: {
      ...(init.headers || {}),
    },
    onFailure: init.onFailure || 'abort',
    retries: init.retries || 0,
    retryCounter: init.retryCounter || 0,
  };
};

const resolveUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }

  let baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

  if (!baseUrl) {
    throw '[API-V2] NEXT_PUBLIC_BASE_URL env variable is required.';
  }

  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, baseUrl.lastIndexOf('/'));
  }

  if (url.startsWith('/')) {
    return baseUrl + `/${API_PATH}` + url;
  }

  return `${baseUrl}/${API_PATH}/${url}`;
};

export const setAuthToken = (token: string) => {
  if (typeof localStorage === 'undefined') {
    throw '[APIV2] Cannot set token server side.';
  }

  localStorage.setItem(JWT_TOKEN_KEY, token);
};

export const setUser = (user: WROAuthUser) => {
  if (typeof localStorage === 'undefined') {
    throw '[APIV2] Cannot set user server side.';
  }

  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const logout = async () => {
  localStorage.removeItem(JWT_TOKEN_KEY);
  localStorage.removeItem(USER_KEY);

  await fetch('/apis/auth/logout', {
    method: 'POST',
  });

  if (typeof window !== 'undefined') {
    window.dispatchEvent(new CustomEvent<'logout'>('logout'));
  }
};

export const getUser = () => {
  if (typeof localStorage === 'undefined') {
    throw '[APIV2] Cannot get user server side.';
  }

  try {
    const userString = localStorage.getItem(USER_KEY);

    if (!userString) {
      return null;
    }

    return JSON.parse(userString) as WROAuthUser;
  } catch (e) {
    return null;
  }
};

export const isAuthenticated = () => {
  const token = localStorage.getItem(JWT_TOKEN_KEY);
  if (!token) {
    return false;
  }

  const tokenPayload = jwtDecode<CustomJwtPayload>(token);

  return tokenPayload.exp && tokenPayload.exp * 1000 > new Date().getTime();
};

export const fetchApiRaw = async (url: string, init: ApiV2RequestInit = {}): Promise<Response> => {
  let _url = resolveUrl(url);
  const _init = resolveRequestInit(init);

  if (init.params) {
    _url += `?${qs.stringify(init.params, { arrayFormat: 'repeat' })}`;
  }

  try {
    const response = await fetch(_url, _init);

    if (!response.ok && _init.onFailure === 'abort') {
      throw new ApiV2Error(`Failed to fetch. ${url}`, response);
    }

    return response;
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      if (e instanceof ApiV2Error) {
        logger.error(e.response);
        throw e;
      }
      throw e;
    }
    if (_init.retryCounter < _init.retries) {
      await delay(2000);

      return await fetchApiRaw(url, {
        ..._init,
        retryCounter: _init.retryCounter + 1,
      });
    }
    if (isApiV2Error(e)) {
      throw e;
    }
    // @ts-ignore
    throw new ApiV2Error('Failed v2 API call.', e);
  }
};

export const fetchApi = async <T extends ApiV2Response>(
  url: string,
  init: ApiV2RequestInit = {},
): Promise<FetchApiV2Result<T>> => {
  const response = await fetchApiRaw(url, init);

  try {
    return {
      data: (await response.json()) as T,
      status: response.status,
      response,
    };
  } catch (e) {
    // can't parse as json
    return {
      data: (await response.text()) as unknown as T,
      status: response.status,
      response,
    };
  }
};

export const userResponseToUserAndToken = (response: FetchApiV2Result<ApiV2User>) => {
  const user: WROAuthUser & { token?: string } = {
    ...response.data,
  };
  delete user.token;

  return {
    user: user as WROAuthUser,
    token: response.data.token,
  };
};

export const login = async (email: string, password: string, rememberMe?: boolean) => {
  try {
    const response = await fetchApi<ApiV2User>('/auth/login', {
      method: 'POST',
      noAuth: true,
      body: JSON.stringify({
        email,
        password,
        rememberMe,
        provider: 'wordpress',
      }),
    });

    const { user, token } = userResponseToUserAndToken(response);

    setAuthToken(token);
    setUser(user);

    if (typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent<'login'>('login'));
    }

    return {
      success: true as const,
      user,
      token,
    };
  } catch (e) {
    logger.error(e, '[APIV2] Login failed:  %s', (e as any)?.message);

    if (isApiV2Error(e)) {
      return {
        success: false as const,
        error: e.response?.status === 400 ? 'invalid_credentials' : 'internal_error',
      };
    }

    return {
      success: false as const,
      error: 'internal_error',
    };
  }
};

export const loginSocial = async (code: string, provider: string, redirectUrl: string = window.location.origin) => {
  try {
    const response = await fetchApi<ApiV2User>('/auth/login', {
      method: 'POST',
      noAuth: true,
      body: JSON.stringify({
        code,
        provider,
        redirectUrl,
      }),
    });

    const { user, token } = userResponseToUserAndToken(response);

    setAuthToken(token);
    setUser(user);

    if (typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent<'login'>('login'));
    }

    return {
      user,
      token,
    };
  } catch (e) {
    logger.error(e, '[APIV2] Login failed:  %s', (e as any)?.message);
    return false;
  }
};

export const refreshToken = async () => {
  try {
    const response = await fetchApi<ApiV2User>('/auth/refresh', {
      method: 'POST',
    });

    if (response.status !== 200 || !response.data) {
      return false;
    }

    const { user, token } = userResponseToUserAndToken(response);

    setAuthToken(token);
    setUser(user);

    return token;
  } catch (e) {
    logger.error('[APIV2] Failed to refresh the access token.');
    return false;
  }
};
